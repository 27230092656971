import { PublicConfig, PublicEnvironment } from '../../models/environment.public';
import { commonEnvVars, devEnv } from 'src/common/environments/environment.public.common';

const config: PublicConfig = {
    appId: 'l7xxafdbee7bb20545c7a374c8a476772ac2',
    appziSrc: `https://w.appzi.io/w.js?token=kWt9x`,
    brand: 'Keeprite',
    brandPath: 'icp',
    publicBaseUrl: 'https://dev-keeprite.hvacpartners.com',
    siteCode: 809,
    ...devEnv
};

export const environment: PublicEnvironment = { ...commonEnvVars(config) };
